import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FtRegistrasiService {
    getApiUrl() {
        return API_URL
    }
    getAllFtRegistrasi(){
        return axios.get(API_URL + `getAllFtRegistrasi`, { headers: authHeader() });
    }
    getAllFtRegistrasiContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFtRegistrasi`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFtRegistrasiContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFtRegistrasiByParent(ftRegistrasiBean){
        return axios.get(API_URL + `getAllFtRegistrasiByParent/${ftRegistrasiBean}`, { headers: authHeader() });
    }
    getAllFtRegistrasiByParentIn(ftRegistrasiIds){
        return axios.get(API_URL + `getAllFtRegistrasiByParentIn/${ftRegistrasiIds}`, { headers: authHeader() });
    }
    getFtRegistrasiById(id){
        return axios.get(API_URL + `getFtRegistrasiById/${id}`, { headers: authHeader() });
    }

    getPostAllFtRegistrasiContainingExt(item){
        return axios.post(API_URL + `getPostAllFtRegistrasiContainingExt`, item, { headers: authHeader() });
    }
    getPostAllFtRegistrasiContainingValidator(item){
        return axios.post(API_URL + `getPostAllFtRegistrasiContainingValidator`, item, { headers: authHeader() });
    }
    getPostAllFtRegistrasiContainingExtKenaikanPangkat(item){

        return axios.post(API_URL + `getPostAllFtRegistrasiContainingExtKenaikanPangkat`, item, { headers: authHeader() });
    }
    getPostAllFtRegistrasiContainingExtKenaikanPangkatValidator(item){

        return axios.post(API_URL + `getPostAllFtRegistrasiContainingExtKenaikanPangkatValidator`, item, { headers: authHeader() });
    }

    updateFtRegistrasi(item){
        return axios.put(API_URL + `updateFtRegistrasi/${item.id}`, item, {headers: authHeader()})
    }
    createFtRegistrasi(item){
        return axios.post(API_URL + `createFtRegistrasi`, item, {headers: authHeader()})
    }
    deleteFtRegistrasi(id){
        return axios.delete(API_URL + `deleteFtRegistrasi/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFtRegistrasi(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFtRegistrasi`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFtRegistrasi`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FtRegistrasiService()