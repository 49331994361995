<template>
  <div>
    <v-container>
      <v-row justify="center" align-content="center">
        <v-col cols="12" md="5" sm="6">
          <v-card class="mt-md-10 elevation-0">
            <v-card-title>
              <v-img
                  :src="enrollImage"
                  height="100%"
                  width="100%">
              </v-img>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" sm="6">
          <v-card class="my-md-10 elevation-0">
            <v-card-title class="pb-0 mb-0 text-h5 font-weight-bold">
              <v-btn @click="back" class="me-4" icon><v-icon class="mr-1" color="orange">mdi-arrow-left</v-icon> Back</v-btn>
            </v-card-title>
            <v-card-title class="text-h6 font-weight-bold d-flex">
              Registrasi Akun Baru,
              <div class="ml-1 text-h5 font-weight-bold" :class="`${lookupRegistrationTypeAccount(registration_type).color}--text`" >
                {{lookupRegistrationTypeAccount(registration_type).description }}
              </div>
            </v-card-title>
            <v-card-subtitle class="grey--text font-weight-light">
              {{lookupRegistrationTypeAccount(registration_type).notes }}
            </v-card-subtitle>
            <v-form v-model="valid" ref="form">

              <v-card-text class="mt-2">
                <v-row class="my-0 py-0">
                  <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                    <v-text-field
                        class="my-0 py-0"
                        prepend-inner-icon="mdi-account"
                        v-model="user.username"
                        :rules="[validateNotEmpty]"
                        required
                        dense
                        placeholder="NIP anda"
                        outlined
                        label="NIP (ID Pengguna Baru)"
                        type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                    <v-text-field
                        class="my-0 py-0"
                        dense
                        outlined
                        v-model="user.email"
                        label="Email"
                        :rules="[validateNotEmpty, validateEmail]"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="my-0 py-0">
                  <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                    <v-text-field
                        class="my-0 py-0"
                        prepend-inner-icon="mdi-whatsapp"
                        v-model="user.phone"
                        :rules="[validatePhoneNumber]"
                        required
                        dense
                        placeholder="08.."
                        outlined
                        label="WhatsApp"
                        type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="my-0 py-0 mt-2">
                  <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                    <v-text-field
                        v-model="user.firstName"
                        class="my-0 py-0"
                        dense
                        outlined
                        label="Nama Depan"
                        :rules="rulesNotEmtpy"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                    <v-text-field
                        v-model="user.lastName"
                        class="my-0 py-0"
                        dense
                        outlined
                        label="Nama Belakang"
                        :rules="rulesNotEmtpy"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="my-0 py-0"  v-if="false">
                  <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                    <v-autocomplete
                        class="my-0 py-0"
                        v-model="user.countryCode"
                        :items="itemsFCountry"
                        :rules="rulesNotEmtpy"
                        item-value="id"
                        item-text="kode1"
                        auto-select-first
                        dense
                        chips
                        outlined
                        deletable-chips
                        color="blue-grey lighten-2"
                        label="Country"
                        persistent-hint
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="my-0 py-0">
                  <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                    <v-text-field
                        v-model="user.password"
                        label="Password"
                        dense
                        outlined
                        :rules="[required, rulesMinLength]"
                        @click:append="showPassword = !showPassword"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        clearable
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="my-0 py-0">
                    <v-text-field
                        v-model="user.retypePassword"
                        label="Password"
                        dense
                        outlined
                        :rules="[required, rulesMinLength]"
                        @click:append="showRetypePassword = !showRetypePassword"
                        :append-icon="showRetypePassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showRetypePassword ? 'text' : 'password'"
                        clearable
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="my-0 py-0">
                  <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                    <v-autocomplete
                        class="my-0 py-0"
                        v-model="user.fdivisionBean"
                        :items="itemsFDivision"
                        :rules="rulesNotEmtpy"
                        item-value="id"
                        item-text="description"
                        auto-select-first
                        dense
                        chips
                        outlined
                        deletable-chips
                        color="blue-grey lighten-2"
                        label="Dinas"
                        persistent-hint
                        hint="Untuk ASN dari luar Kota Palangka Raya, silahkan memilih *Luar Kota Palangka Raya*"
                    ></v-autocomplete>
                  </v-col>
                  <v-col v-if="false" cols="12" md="12" sm="12" class="my-0 py-0">
                    <v-text-field
                        class="my-0 py-0"
                        dense
                        outlined
                        v-model="user.companyName"
                        label="Nama Organisasi Perusahaan/Instansi"
                        :rules="[validateNotEmpty]"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="false">
                  <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                    <v-autocomplete
                        v-model="user.sectorsInterst"
                        class="my-0 py-0"
                        :items="itemsFtSectorType"
                        item-value="id"
                        item-text="description"
                        auto-select-first
                        dense
                        chips
                        outlined
                        deletable-chips
                        color="blue-grey lighten-2"
                        label="Sectors you may interest"
                        multiple
                        persistent-hint
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mt-6">
                  <v-col cols="12" md="12" sm="6" class="my-0 py-0">
                    <v-checkbox
                        v-model="privacyPolicy"
                        dense
                        hide-details
                    >
                      <template v-slot:label>
                        <span>I agree to all the <span class="pink--text lighten-3">Terms</span> and <span class="pink--text lighten-3">Privacy Policies</span></span>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="mt-8"></v-row>
                <v-row v-if="errorMessage" class="text-center">
                  <v-col class="subtitle-2 red--text">
                    {{ errorMessage }}
                    <v-icon small color="red">mdi-thumb-down</v-icon>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="6" class="my-0 py-0">
                    <v-btn @click="handleSignUp" :disabled="!valid || privacyPolicy===false" block color="teal" class="white--text">
                      Register / Daftar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-8 text-center">
                  <v-col cols="12" md="12" sm="6" class="my-0 py-0">
                    <div>Already have account? <router-link class="no-underline" to="/login">
                      <span class="pink--text">Login here</span>
                    </router-link></div>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="loading"
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please wait
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import User from "@/models/user";
import ERole from "@/models/e-role";
import FDivisionService from "@/services/apiservices/f-division-service";
import FtRegistrationRegService from "@/services/apiservices-account-registration/ft-registration-reg-service";
import FtRegistrationReq from "@/models/account-registration/ft-registration-req";

export default {
  name: "UserRegistration",
  components: {},
  data() {
    return {
      privacyPolicy: false,
      typeAccount: '',
      itemsTypeAccount:[
        {id:1, description:'ASN Kota Palangka Raya', notes:'Untuk ASN atau Pegawai di Lingkungan Kota Palangka Raya', color:"amber", icon:'mdi-account-tie'},
        {id:2, description:'ASN Luar Daerah', notes:'Untuk ASN Luar Daerah yang ingin mengajukan pindah ke dalam Lingkungan Pemerintah Kota Palangkaraya', color:"teal", icon:'mdi-account-convert'},
        // {id:3, description:'Forum Jakonku', notes:'Forum khusus bina konstruksi Prov Kalteng', color:"indigo", icon:'mdi-forum'},
      ],
      showRetypePassword: false,
      showPassword: false,
      confirmPassword: '',
      valid: false,
      rulesNotEmtpy: [(v) => !!v || "must contain a value"],
      validateNotEmpty: (value) => !!value || "must contain a value",
      validateEmail: (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value) || "Wrong email format";
      },

      validatePhoneNumber: (value) => {
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(value) || "Only number allowed";
      },
      rulesMinLength: (v) => {
        return (v && v.length >=5) || "Password must be more than 5 character";
      },

      date: format(parseISO(new Date().toISOString()), "dd-MMM-yyyy"),
      menuDate1: false,
      enrollImage: require("@/assets/images/register-pict.png"),
      itemsFDivision: [],
      user: new User("", ""),
      loading: false,
      snackbar: false,
      errorMessage: "",
      success: false,
      registration_type: '',
      itemsFCountry:[],
      itemsFtSectorType: [],
      itemsRoles: [ERole.ROLE_USER],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

  },
  methods: {

    handleSignUp() {
      if (this.user.password !== this.user.retypePassword) {
        this.errorMessage = "Password not match";
        this.snackbar = true;
        return;
      }

      if (this.$refs.form.validate()) {

        this.success = false;

        let ftRegistrationReq = new FtRegistrationReq();
//format =  @JsonFormat(shape = JsonFormat.Shape.STRING, pattern = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
        ftRegistrationReq.timeStart = new Date().toISOString()
        ftRegistrationReq.timeEnd = new Date().toISOString()


        if(this.registration_type === '1'){
          ftRegistrationReq.roleName = ERole.ROLE_USER;
        } else{
          return;
        }

        ftRegistrationReq.firstName = this.user.firstName;
        ftRegistrationReq.lastName = this.user.lastName;
        ftRegistrationReq.fullName = `${this.user.firstName} ${this.user.lastName}`;
        ftRegistrationReq.email = this.user.email;
        ftRegistrationReq.password = this.user.password;
        ftRegistrationReq.fdivisionBean = this.user.fdivisionBean;
        // ftRegistrationReq.isAccountNonLocked = false

        ftRegistrationReq.username = this.user.username;
        // ftRegistrationReq.whatsApp = Number(this.user.username);
        ftRegistrationReq.whatsApp = Number(this.user.phone);

        ftRegistrationReq.countryCode = this.user.countryCode;
        //Bisa dimatikan atau di hidupkan
        if (this.user.countryCode===0){
          ftRegistrationReq.countryCode = 62;
        }

        ftRegistrationReq.type = 1; //1. User Registration 2. Change Password
        /**
         * 0 = email
         * 1 = Whatsapp
         * 2 = SMS
         */
        ftRegistrationReq.tokenMedia = 1;

        ftRegistrationReq.remarkJson = {
          companyName: this.lookupFDivision(this.user.fdivisionBean).description,
          department: '',
          // sectorsInterest: this.user.sectorsInterst
        }

        this.loading = true
        FtRegistrationRegService.createFtRegistrationReqPublic(ftRegistrationReq).then(
            response => {
              this.loading = false
              response.toLocaleString()
              this.$store.dispatch('registration/setItemFtReg', response.data);
              this.$router.push("/registration-masukkan-otp");
            },
            (error) => {
              console.log(error);
              this.loading = false
              this.errorMessage = `${error.response.data.message}. Fail create ${this.user.username}`;

            }
        );


      }

    },
    back(){
      this.$router.push("/select-account-type")
    },
    computedDateFormattedDatefns(value) {
      return value ? format(parseISO(value), "dd MMMM yyyy") : "";
    },

    fetchParent() {
      FDivisionService.getAllFDivisionPublic().then((response) => {
        let luarDaerah = 'luar'
        if(this.registration_type === '1'){
          this.itemsFDivision = response.data.filter(
              (item) => item.statusActive === true && !item.description.toUpperCase().includes(luarDaerah.toUpperCase()) &&
                  ! item.description.toUpperCase().includes("BIDANG")
          );
        } else if(this.registration_type === '2'){
          this.itemsFDivision = response.data.filter(
              (item) => item.statusActive === true && item.description.toUpperCase().includes(luarDaerah.toUpperCase()) &&
                  ! item.description.toUpperCase().includes("BIDANG")
          );
        }

      });

    },

    required: function(value) {
      if (value!==undefined) {
        return true;
      } else {
        return 'This field is required.';
      }
    },
    lookupRegistrationTypeAccount(id) {
      let result = this.itemsTypeAccount.filter(x => x.id === parseInt(id))
      if (result.length > 0) {
        return result[0]
      }else {
        return ''
      }
    },

    fetchFCountry() {
      fetch('https://restcountries.com/v3.1/all?fields=name,idd,cca2,cca3,region,subregion,timezones,languages')
          .then(response => response.json())
          .then(data => {
            // const countryList = document.getElementById('country-list');
            data.forEach(country => {


              let name = country.name.common;
              let description = country.name.official;
              let region = country.region;

              let countryCode = parseInt((country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')).replace('+', ''), 10);
              this.itemsFCountry.push({id: countryCode, countryCode: countryCode, kode1: name, kode2:country.cca2, description: description,
                region: region,subregion:country.subregion, timeZone: country.timezones, languages: country.languages
              })

            });
          })
          .catch(error => console.error('Error:', error));
    },
    getCountryName(countryCode) {
      const country = this.itemsFCountry.find(c => c.kode2 === countryCode);
      return country ? country.id : null;
    },
    getLocalInfo() {
      fetch('https://ipapi.co/json/')
          .then(response => response.json())
          .then(data => {

            this.user.countryCode = this.getCountryName(data.country_code);

          })
          .catch(error => console.error('Error:', error));
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.fetchParent();
    this.registration_type = this.$route.query.registration_type

    this.fetchFCountry()
    this.getLocalInfo()

  },
};
</script>
<style scoped>

.no-underline {
  text-decoration: none;
}
</style>


